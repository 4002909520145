import styled from "styled-components";

export const TemoignagesWrapper = styled.div`
  display: flex; 
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    padding:0 5vw;
  }
  & h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
  }
`;

export const TemoignagesContainer = styled.div`
display: flex;
margin-top: 24px;
@media (min-width: 1024px) {
    width: 70%;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
  }
`;


export const TemoignageItem = styled.div`
  padding: 0 0 24px 24px;
  @media (min-width: 1024px) {
    padding: 0 40px;
  }
  border-left: 1px solid black;
  & h5{
    font-weight: 400;
  }
  & p:first-child {
    margin-top: 24px;
  }
  &>div:first-child {
    display: flex;
    justify-content: space-between;
    & div.image{
      border-radius: 50%;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      overflow: hidden;
    }
  }
`;

export const CommanditairesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 50px;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const CommanditaireItem = styled.div`
  
`;
