import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Core/Layout";
import { HTMLContent } from "../components/Content";
import { AboutPageComponent } from "../components/Pages/About";

// eslint-disable-next-line
export const AboutPageTemplate = (props) => {
  return (
    <AboutPageComponent {...props} />
  );
};

AboutPageTemplate.propTypes = {
  // TODO 
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: content } = data;
  const { frontmatter } = content;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={frontmatter.title}
        title2={frontmatter.title2}
        image={frontmatter.image}
        smallImage={frontmatter.smallImage}
        btnLabel={frontmatter.btnLabel}
        podcast={frontmatter.podcast}
        credits={frontmatter.credits}
        temoignage={frontmatter.temoignage}
        body={content.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export { Head } from "../components/Core/HtmlHead";

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        title2
        btnLabel
        podcast {
          title
          image{
            ...imagePodcastBlock
          }
          smallImage: image {
            ...imagePodcastSmallBlock
          }
          body
          btnLabel
        }
        image{
          ...imageBlock
        }
        smallImage: image {
          ...imageSmallBlock
        }
        temoignage {
          title
          items {
            featured
            name
            company
            body
            image{
              alt
              image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 120, height: 120)
                }
              }
            }
          }
        }
        credits {
          title
          body
          commanditaires {
            title
            link
            image{
              alt
              image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 300, height: 300)
                }
              }
            }
          }
        }
      }
    }
  }
`;
