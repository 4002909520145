import * as React from "react";
import ReactMarkdown from 'react-markdown'
import Content from "../../Content";
import { Section, ButtonLink} from "../../styles";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import Temoignages from "./Temoignages";
import { Credits } from "./Credits";

export const AboutPageComponent = ({
  contentComponent,
  title,
  title2,
  image,
  smallImage,
  btnLabel,
  podcast,
  credits,
  temoignage,
  body
}) => {

  const mdRenderers = { 
    link: (props) => {
      console.log(props, 'renderer Link')
      if (props.href.match('http')) {
        return <a href={props.href} target="_blank" rel="nofollow noreferrer noopener">{props.children}</a>
      }
        return <a href={props.href}>{props.children}</a>
      } 
  };

  const img = withArtDirection(getImage(image.image), [
    {
      media: "(max-width: 1024px)",
      image: getImage(smallImage.image),
    },
  ]) || image.image;
  const imgPodcast = withArtDirection(getImage(podcast.image.image), [
    {
      media: "(max-width: 1024px)",
      image: getImage(podcast.smallImage.image),
    },
  ]) || podcast.image.image;
  const PageContent = contentComponent || Content;

  return (
    <>
      <Section variant="about">
        <div className="text">
          <h2>
            {title}
          </h2>
          <h3>
            {title2}
          </h3>
          <PageContent content={body} />
          <ButtonLink to='/services'>
            {btnLabel}
          </ButtonLink>
        </div>
        <div className="image image-top">
          <GatsbyImage image={img} alt={image.alt} title={image.alt} />
        </div>
      </Section>
      <Section variant="white">
        <Temoignages temoignages={temoignage} />
      </Section>
      <Section variant="blog">
      <div className="content">
        <div className="text">
          <h3 className="blogue_title">
            {podcast.title}
          </h3>
          <ReactMarkdown linkTarget='_blank' renderers={mdRenderers}>
            {podcast.body}
          </ReactMarkdown>
          <ButtonLink to='/blog'>
            {podcast.btnLabel}
          </ButtonLink>
        </div>
        <div className="image">
          <GatsbyImage image={imgPodcast} alt={podcast.image.alt} title={podcast.image.alt} />
        </div>
        </div>
      </Section>
      <Section variant="white">
        <Credits credits={credits} />
      </Section>
    </>
  );
}