import React from "react";
import ReactMarkdown from 'react-markdown'
import { CommanditairesWrapper, CommanditaireItem } from "./styles";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

export const Credits = ({
  credits
}) => {

  return (
    <>
      <h3>
          {credits.title}
      </h3>
      <div className="content">
        <ReactMarkdown>
            {credits.body}
        </ReactMarkdown>
      </div>
      <CommanditairesWrapper>
        {credits.commanditaires.map((c, i) => (
          <CommanditaireItem key={i}>
            <a href={c.link} rel="noreferrer" target="_blank">
              <GatsbyImage image={getImage(c.image.image) || c.image.image} alt={c.image.alt} title={c.image.alt} />
            </a>
          </CommanditaireItem>
        ))}
      </CommanditairesWrapper>
    </>
  );
};