import React from "react";
import ReactMarkdown from 'react-markdown'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { TemoignagesContainer, TemoignageItem, TemoignagesWrapper } from "./styles";
import { useRef } from "react";
import { CarouselArrow } from "../../Design/CarouselArrow";
import { CarouselContainer } from "../Blog/styles";
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image";

export const Temoignages = ({
  temoignages
}) => {
  const carousel = useRef(null);

  const slidePrev = () => {
    carousel.current && carousel.current.slidePrev();
  };

  const slideNext = () => {
    carousel.current && carousel.current.slideNext();

  };

  const TemoignagesItems = (temoignages?.items?.length && temoignages.items.map((t) => (
    <TemoignageItem>
      <div>
        <div className="text">
          <h4>{t.name}</h4>
          {t.company && (
            <h5>{t.company}</h5>
          )}
        </div>
        <div className="image">
          {t?.image?.image ? (
            <GatsbyImage image={getImage(t.image.image) || t.image.image} alt={t.image.alt} title={t.image.alt} />
          ) : (
            <StaticImage src="../../../img/icon-user.png" alt={t.name} />
          )}
        </div>
      </div>
      <div>
        <ReactMarkdown>
          {t.body}
        </ReactMarkdown>
      </div>
    </TemoignageItem>
  ))) || [];
 
  return (
    <TemoignagesWrapper>
      <h3>
          {temoignages.title}
      </h3>
      <TemoignagesContainer>
        <CarouselArrow direction="left" onClick={slidePrev}/>
        <CarouselContainer>
            <AliceCarousel 
              ref={carousel}
              disableButtonsControls
              responsive={{
                0: { items: 1 },
                1024: { items: 1 },
              }}
              items={TemoignagesItems} 
              infinite
            />
          </CarouselContainer>
        <CarouselArrow direction="right" onClick={slideNext}/>
      </TemoignagesContainer>
    </TemoignagesWrapper>
  );
}

export default Temoignages;